import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  template: `<button class="btn btn-action-outline" (click)="editMasterData()"><i  class="lni-pencil"></i></button> &nbsp; <button class="btn btn-action-outline" (click)="deleteMasterData()"><i  class="lni-trash"></i></button>`
})
export class MyLinkRendererComponent implements AgRendererComponent {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  editMasterData() {
    console.log("Edit clicked for master Data");
  }
  deleteMasterData() {
    console.log("Delete clicked for master Data");
  }
}
