import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TranslateEnumToValue'
})

export class TranslateEnumToValue implements PipeTransform {
  transform(type: string, value: string): string {

    console.log(' TranslateEnum to value  pipe called...../////',type,value);
    const enumsAndValues = JSON.parse(localStorage.getItem('enums'));
    console.log('enumsAndValues.type.value', type, value, enumsAndValues[type]);
    if (enumsAndValues[type]) {
      return enumsAndValues[type][value];
    } else {
      return value;
    }

  }
}
