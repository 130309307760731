import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeMasterService {
  emp;
  selectedRowDataEmp;
  selectedNewRowDataEmp;
  selectedEmpFromSearch;

  employeeChanged = new Subject();
  employeeSearchcardClear = new Subject();
  clearDoc = new Subject();
  basicDetailsTabChanged = new Subject();
  empSalaryEffectiveFrom: any;
  ticketID;

  constructor() { }
  setEmp(val) {
    this.selectedRowDataEmp = val;
    this.employeeChanged.next(val);
  }
  getEmp() {
    return this.selectedRowDataEmp;
  }
  newSetEmp(val) {
  this.selectedNewRowDataEmp = val;
  }
  newGetEmp() {
  return this.selectedNewRowDataEmp;
  }

  setSelectedEmpFromSearch(employee) {
    this.selectedEmpFromSearch = employee;
  }
  getSelectedEmpFromSearch() {
    return this.selectedEmpFromSearch;
  }

  clearSelectedSearchCard(){
    this.employeeSearchcardClear.next();
  }

  clearDocument(){
    this.clearDoc.next();
  }

  setEmpSalaryEffectiveFrom(effectiveFrom) {
    this.empSalaryEffectiveFrom = effectiveFrom;
  }
  getEmpSalaryEffectiveFrom() {
    return this.empSalaryEffectiveFrom;
  }

  employeeBasicDetailsTabChanged(){
    this.basicDetailsTabChanged.next();
  }
  setTicket(val) {
    this.ticketID = val;
  }
  getTicket() {
    return this.ticketID;
  }

}
