import { LoadingService } from 'src/app/services/loading.service';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr'
import { RefreshAuth } from './../model/auth';
import { environment } from './../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService implements CanActivate {
  // token expiry alert
  isTokenGoingToExpiry = new Subject();
  tokenConformationFromUser = new Subject();

  constructor(private _http: HttpClient, private _jwtHelperService: JwtHelperService, private _router: Router, private toastr: ToastrService) {
    this.tokenConformationFromUser.subscribe(res => {
      this.prepareRefreshTokenObj()
    })
  }

  canActivate(): boolean {
    return (this.toastr.currentlyActive == 0) ? true : false;
  }

  // congnito login call
  login(authObject, base64Input) {
    console.log(
      'auth service called --authObject---base64Input',
      authObject,
      base64Input
    );

    const httpOptions = {

      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + base64Input
      })
    };
    let body =
      'grant_type=' +
      authObject.grant_type +
      '&client_id=' +
      authObject.client_id +
      '&redirect_uri=' +
      authObject.redirect_uri +
      '&code=' +
      authObject.code;
    return this._http.post(environment.cognitoAuthUrl, body, httpOptions);
  }

  // congnito refresh token call
  refreshToken(authObject) {
    console.log('Auth Onject at refreshToken');
    const httpOptions = {
      headers: new HttpHeaders({
        'x-amz-target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        'Content-Type': 'application/x-amz-json-1.1'
      })
    };
    return this._http.post(environment.cognitoRefeshTokenUrl, authObject, httpOptions);
  }

  // to decode accestoken
  decodeJWT(accessToken) {
    return this._jwtHelperService.decodeToken(accessToken);
  }

  // to check checkTokenExpiry
  checkTokenExpiry() {
    console.log("checkTokenExpiry");
    const token = localStorage.getItem('access_token');
    if (token) {
      const jwtToken = this.decodeJWT(token);
      if (jwtToken && jwtToken.exp) {
        console.log('jwtToken.exp', jwtToken.exp, moment().unix());
        // jwtToken.exp giving expiry time in second
        // moment().unix() returns current time in second
        const timeStampDiff = (jwtToken.exp - moment().unix());
        console.log('checkTokenExpiry called  timeStampDiff----', timeStampDiff);
        // check timeStampDiff is less than or equalto 10 mints
        if (timeStampDiff <= 600) {
          console.log('refreshToken  called');
          this.isTokenGoingToExpiry.next();
        }
      }
    }
  }

  ////// To prepare  Refresh token object  method definition ///////
  prepareRefreshTokenObj() {
    try {
      let refeshAuthObj = new RefreshAuth();
      refeshAuthObj.ClientId = environment.cognitoClientId;
      refeshAuthObj.AuthFlow = 'REFRESH_TOKEN_AUTH';
      refeshAuthObj.AuthParameters = {
        'REFRESH_TOKEN': localStorage.getItem('refreshtoken'),
        'SECRET_HASH': localStorage.getItem('refreshHash'),
      }

      this.refreshToken(refeshAuthObj).subscribe((response: any) => {
        if (response.AuthenticationResult && response.AuthenticationResult.AccessToken) {
          console.log('====Token Refreshed====');
          localStorage.setItem('access_token', response.AuthenticationResult.AccessToken);
        }

      },
        (error) => {
          console.log('refresh token inside error', error);
          localStorage.clear();
          this._router.navigate(['/login']);
        }
      );
    } catch (exception) {
      localStorage.clear();
      this._router.navigate(['/login']);
      console.error(exception);
    }
  }

  logout() {
    console.log('logout clicked');
    let redirectURL = environment.cognitoLogoutUrl + '?client_id=' + environment.cognitoClientId + '&logout_uri=' + window.location.protocol + '//' + location.host + '/'
    localStorage.clear();
    window.location.replace(redirectURL);
  }
}
