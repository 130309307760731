import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateCodeToValue } from './translateCodeToValue.pipe';
import { TranslateEnumToValue } from './translateEnum.pipe';
import { NumberWithoutDecimal } from './numberWithoutDecimal.pipe';


@NgModule({
  declarations: [TranslateCodeToValue, TranslateEnumToValue, NumberWithoutDecimal], // <---
  imports: [CommonModule],
  exports: [TranslateCodeToValue, TranslateEnumToValue, NumberWithoutDecimal], // <---
  providers: [TranslateCodeToValue, TranslateEnumToValue, NumberWithoutDecimal]
})
export class PipesModule { }
