import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.css']
})
export class MasterActionButtonsComponent implements OnInit {

  parentOrgId;
  parentOrgIdBool: boolean;

  ngOnInit() {
    let rowData = this.params;
    this.parentOrgId = rowData.data.parentOrg;
    console.log("In masterData  :", rowData.data.parentOrgId);
    if (this.parentOrgId == '') {
      console.log("parent id  null");
      this.parentOrgIdBool = false;
    }
    else {
      console.log("parent id exist");
      this.parentOrgIdBool = true;
    }
  }

  show: boolean = true;
  data: any;
  params: any;
  constructor() { }

  agInit(params) {
    this.params = params;
    this.data = params.value;
  }

  editRow() {
    let rowData = this.params;
    let i = rowData.rowIndex;
    console.log(rowData);
    alert(i);

  }

  showOrganizationHr() {
    let rowData = this.params;
    let i = rowData.rowIndex;
    console.log("viewRow =>", rowData);
    alert(rowData.data['orgName']);
  }

  deleteRow() {
    let rowData = this.params;
    console.log(rowData);
  }






}
