import { HttpClientService } from './../services/httpClient.service';
import { LoadingService } from './../services/loading.service';
import { CognitoAuth } from './../model/auth';
import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedInUserService } from 'src/app/services/loggedInUser.service';
import { AuthService } from '../services/auth.service';

// to decode Jwt
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isAuthProgress: boolean = true;
  userid: any;
  OrgId: any;
  LoginName: any;
  employee: any;
  public currentYear = (new Date()).getFullYear();//Display current year
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _jwtHelperService: JwtHelperService,
    private _httpClientService: HttpClientService
  ) { }

  ngOnInit() {
    let code = localStorage.getItem('authorisationcode') || '';
    if (code == '') {
      this.isAuthProgress = false;
    } else {
      this.isAuthProgress = true;
      console.log("isAuthProgress", this.isAuthProgress);
      localStorage.setItem('isAuthProgress', this.isAuthProgress.toString());
      console.log('code', code);
      this.verifyAutnticationCode(code);
    }

  }

  // verifying authentication code
  verifyAutnticationCode(authCode) {
    try {
      const auth_code = authCode;
      let base64Input = btoa(
        environment.cognitoClientId + ':' + environment.cognitoClientSecret
      );
      let authObject = new CognitoAuth();
      authObject.grant_type = 'authorization_code';
      authObject.client_id = environment.cognitoClientId;
      authObject.redirect_uri = window.location.protocol + '//' + location.host + '/';
      authObject.code = authCode;
      this._authService.login(authObject, base64Input).subscribe(
        (response: any) => {
          console.log('response.json().access_token', response.access_token);
          let decodedToken = this._jwtHelperService.decodeToken(response.access_token);
          console.log('decodedToken', decodedToken);
          console.log('response.json().refresh_token', response.refresh_token);
          localStorage.setItem('access_token', response.access_token);
          localStorage.setItem('refreshtoken', response.refresh_token);
          localStorage.setItem('logged_in_user', JSON.stringify(decodedToken));
          localStorage.removeItem('isAuthProgress');

          // getting current user ME
          this._httpClientService.get('me?loginname=' + decodedToken.username, 'apiDevBaseUrlsys').subscribe((res: any) => {
            console.log('ME response', res);
            if (res) {
              localStorage.setItem('ME', JSON.stringify(res.d));
              this.getEmployeeDetails();

            }

          }, (err: any) => {
            console.log('ME errro', err);
          });



        },
        error => {
          console.log('error login', error);
          this.isAuthProgress = false;
          localStorage.removeItem('isAuthProgress');
          this._router.navigate(['/login']);
          if (error && (error.status == 401 || error.status == 400 || error.status == 403)
          ) {
            // Handling Unauthorized access
            console.log('error', error);
          }
        }
      );
    } catch (exception) {
      localStorage.clear();
      console.log('catch exception', exception);
      this._router.navigate(['/login']);
    }
  }



  getEmployeeDetails() {
    //const userid =  parseInt(JSON.parse(localStorage.getItem('userid')));
    const loginuser = JSON.parse(localStorage.getItem('ME'));
    this.userid = loginuser.ID;
    this.OrgId = loginuser.OrgId;
    this.LoginName = loginuser.LoginName;
    // const userid =  105;
    this._httpClientService.get("orgs/" + this.OrgId + "/employees/" + this.LoginName, "apiDevBaseUrlemp").subscribe((data: any) => {
      // this.employee = data.d;
      console.log("employee", data.d);
      if (data.d) {
        localStorage.setItem('Login_UserProfile', JSON.stringify(data.d));
        this.getReportingManagerDetails();
        this._router.navigate(['/ess']).then(res => {
          this.isAuthProgress = false;  // need to think about it.
        });
      }

    })

  }
  getReportingManagerDetails() {
    // https://api-qa.vpdpaysstar.com/api/emp/rmreportingemplist/995
    const userid = JSON.parse(localStorage.getItem('Login_UserProfile')).EmpID;
    this._httpClientService.get("rmreportingemplist/" + userid, "apiDevBaseUrlemp").subscribe((data: any) => {
      let ReportManger;
      

      if (data.d == null || data.d == '') {
        ReportManger = false;
      } else {
        ReportManger = true;
        localStorage.setItem('Leave_Applied_Emp_List', JSON.stringify(data.d));
      }
      localStorage.setItem('Reporting_Manager', ReportManger);
      
      console.log('ReportManger', ReportManger);
    })

  }

  authenticate() {
    localStorage.removeItem('access_token');
    localStorage.clear();
    let redirectURL = environment.awsCognitoUrl + environment.cognitoClientId + '&redirect_uri=' + window.location.protocol + '//' + location.host + '/';
    window.location.href = redirectURL;
  }
}
