import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
    employeeProfileDetails;
  constructor() { }
   setEmployeeProfileDetails(val){
    this.employeeProfileDetails = val;
  }
   getEmployeeProfileDetails(){
    return this.employeeProfileDetails;
  }
}
