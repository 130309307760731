import { Component, OnInit } from "@angular/core";
import { HttpClientService } from "src/app/services/httpClient.service";
@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.css"]
})
export class UploadFileComponent implements OnInit {

  files: any = [];
  private base64textString: String = "";

  uploadFile1(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element.name)
    }
  }
  deleteAttachment(index) {
    this.files.splice(index, 1)
  }

  // file convertion to base64 start
  uploadFile(evt) {
    // this.files = evt.target.files;
    this.files = evt;
    var file = this.files[0];
    console.log("File", file);
    console.log("File name", file.name);

    if (this.files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    console.log("call from the upload-file ", btoa(binaryString));

    //post call
    if (this.base64textString != null) {
      this.postData();
    }

  }

  postData() {
    this.httpClientService
      .post("import", this.base64textString, "apiDevBaseUrlimport")
      .subscribe(orgRes => {
        console.log("upload file", orgRes);
        console.log("orgRes inside method", orgRes);
      });
  }
  // file convertion to base64 end

  constructor(private httpClientService: HttpClientService) { }

  ngOnInit() {
     

  }
}
