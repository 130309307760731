import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { Router } from '@angular/router';
import { HttpClientService } from 'src/app/services/httpClient.service';
import { HttpClient } from '@angular/common/http';
import { EditProfileService } from 'src/app/services/edit-profile.service';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit {
  loggedInUser;
  ME: any;
  link: any;
  defaultProfile: boolean;
  Login_User: any;
  companyLogo: any;


  logout() {
    console.log('logout clicked');
    this._authService.logout()
  }
  getProfilePicById(logo) {
    try {
      this.loader.loaderStart();
      this.httpClientService
        .get('docs/' + logo, 'apiDevBaseUrlsys')
        .subscribe((getLogoByClientIdRes: any) => {
          console.log('get link of image', getLogoByClientIdRes.d);
          this.link = getLogoByClientIdRes.d;
          if (getLogoByClientIdRes.d) {
            this.defaultProfile = false;
          }
          // let editlogosplit = this.link.split('_');
          // console.log('this.editFilename', editlogosplit[3].split('?')[0]);
          // this.editFilename = editlogosplit[3].split('?')[0]
          this.loader.loaderEnd();
        },
          (err: any) => {
            this.loader.loaderEnd();
            this.toasterService.error(err.error.m);
          }
        );
    } catch (err) {
      this.loader.loaderEnd();
      this.toasterService.error(err);
    }

  }

  // getCompanyByLogo
  getCompanyLogoByFileName(logo) {
    try {
      this.loader.loaderStart();
      this.httpClientService
        .get('docs/' + logo, 'apiDevBaseUrlsys')
        .subscribe((getLogoByClientIdRes: any) => {
          this.loader.loaderEnd();
          this.companyLogo = getLogoByClientIdRes.d;
          console.log("company logo", this.companyLogo);
        },
          (err: any) => {
            this.loader.loaderEnd();
            this.toasterService.error(err.error.m);
          }
        );
    } catch (err) {
      this.loader.loaderEnd();
      this.toasterService.error('Something went wrong.Please try again');
    }

  }

  constructor(
    private http: HttpClient,
    private httpClientService: HttpClientService,
    private _router: Router,
    private loader: LoadingService,
    private toasterService: ToasterService,
    private _profileService: ProfileService,
    public editProfile: EditProfileService,
    private _authService: AuthService
  ) { }

  ngOnInit() {
    this.loggedInUser = JSON.parse(localStorage.getItem('logged_in_user'));
    this.ME = JSON.parse(localStorage.getItem('ME'));
    const Login_UserProfile = JSON.parse(localStorage.getItem('Login_UserProfile'));
    console.log('logged_in_user ', this.loggedInUser);
    this.defaultProfile = true;
    if (Login_UserProfile.ProfilePic) {
      this.getProfilePicById(Login_UserProfile.ProfilePic);
    }
    this.editProfile.UserEditClicked.subscribe(res => {
      console.log("subject varible called", res);
      const editData = this.editProfile.getUserRowData();
      if (editData) {
        this.getProfilePicById(editData.ProfilePic);
      }
    })
    // get company logo
    let fileName = this.ME.OrgLogo;
    if (this.ME.OrgLogo) {
      this.getCompanyLogoByFileName(fileName);
    }
    console.log('companyLogo', fileName);



  }

}
