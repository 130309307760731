export class CognitoAuth{

  "grant_type":string;
  "client_id":string;
  "redirect_uri":string;
  "code":string;
}

export class RefreshAuth{

  "ClientId":string;
  "AuthFlow":string;
  "AuthParameters":any;
}
