import { DragDropDirective } from './directives/drag-drop.directive';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { FormsModule } from '@angular/forms';

import { MyLinkRendererComponent } from './components/ag-grid/my-link-renderer';
import { MasterActionButtonsComponent } from './components/ag-grid/action-buttons/action-buttons.component';
import { AgGridComponent } from './components/ag-grid/ag-grid.component';
import { CUSTOM_ELEMENTS_SCHEMA, Pipe } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './components/table/table.component';

// ag-grid
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { ErrorHandlingComponent } from './components/error-handling/error-handling.component';
import { SuccessBlogComponent } from './components/success-blog/success-blog.component';

import { DigitOnlyDirective } from '../shared/directives/digit-only.directive'

import { ReactiveFormsModule } from '@angular/forms';
import { LogoUploadComponent } from './components/logo-upload/logo-upload.component';
import { UploadITFileComponent } from './components/uploadItDeclarationFiles/upload-itfile.component'
import { from } from 'rxjs';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';



@NgModule({
  declarations: [TableComponent, DigitOnlyDirective, DragDropDirective, AgGridComponent, MasterActionButtonsComponent, MyLinkRendererComponent, ErrorHandlingComponent, SuccessBlogComponent, UploadFileComponent, LogoUploadComponent, UploadITFileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([null]),
    PipesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [TableComponent, DragDropDirective, DigitOnlyDirective, AgGridComponent, MasterActionButtonsComponent, ErrorHandlingComponent, SuccessBlogComponent, UploadFileComponent, LogoUploadComponent, UploadITFileComponent],
  entryComponents: [MasterActionButtonsComponent, MyLinkRendererComponent],
  providers: [DragDropDirective, DigitOnlyDirective]
})
export class SharedModule { }
