import { ToasterService } from './services/toaster.service';
import { Component, OnInit } from '@angular/core';
import { LoadingService } from './services/loading.service'
import { timer } from 'rxjs';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'AngularTemp';
  loader;

  ngOnInit() {
    // this.ToasterService.success("this is a sample toaster");

    // window.addEventListener("beforeunload", function(e) {
    //   var confirmationMessage = "please stop";
    //   console.log("cond");
    //   e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
    //   return confirmationMessage;              // Gecko, WebKit, Chrome <34
    // });
    timer(5000, 420000).subscribe(val => this._authService.checkTokenExpiry());
  }
  constructor(private ToasterService: ToasterService, private loadingService: LoadingService, private _authService: AuthService) {
    this.loader = this.loadingService;

  }
}
