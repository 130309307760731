import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/services/httpClient.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Router } from '@angular/router';
import Utils from 'src/app/shared/utility.functions';
import { MessageConstants } from 'src/app/services/message-constants';

@Component({
  selector: 'app-it-statement',
  templateUrl: './it-statement.component.html',
  styleUrls: ['./it-statement.component.css']
})

export class ITStatementComponent implements OnInit {

  // GET DATA VARIBLE
  public itStatementdata: any;
  public ptsum: any;
  public empid: any;
  public fy: any;
  public selectedEmpData: any;
  public branch: string;
  public OrgName = JSON.parse(localStorage.getItem('ME')).OrgName;
  // public OrgName = JSON.parse(localStorage.getItem('entitie')).Name;
  public ITEmpROws;
  dataElArry: any;
  matchedDataEl: boolean;
  formatedDataMonthlyIncArry: any = [];
  formatedDataDeductionsArry: any = [];
  formatedDataPerquisiteSectionArry: any = [];
  formatedDataExemptionsSectionArry: any = [];
  formatedDataPreviousEmplyrSectionArry: any = [];
  formatedDataTaxOnEmploymentSecArry: any = [];
  formatedDataEightySectionArry: any = [];
  formatedDataEightySectionValueArry: any = [];
  eightySectionGrossTotal: any;
  refresh() {
    this.getItData();
  }

  // API GET METHOD  CALL
  getItData() {
    try {
      this._LoadingService.loaderStart();
      this._httpClientService
        .get('payroll/empincometaxstmt' + '?empId=' + this.empid + '&fy=' + this.fy, 'apiDevBaseUrlorg')
        .subscribe(
          (itresponse: any) => {
            this._LoadingService.loaderEnd();
            let EmpIncomeData = [];
            EmpIncomeData.push(itresponse.d)
            this.itStatementdata = EmpIncomeData;
            console.log('itresponse', this.itStatementdata[0].MonthlyIncome.Rows);
            this.eightySectionGrossTotal = 0;
            for (let i = 0; i < this.itStatementdata[0].EightySection.Rows.length; i++) {
              this.dataElArry = this.itStatementdata[0].EightySection.Rows[i].DataElements;
              this.eightySectionGrossTotal += this.dataElArry[1].Value;
            }

            this.removeZerosMonthlyIncomeData();
            this.removeZerosDeductionsData();
            this.removeZerosPerquisiteSectionData();
            this.removeZerosExemptionsSectionData();
            this.removeZerosPreviousEmplyrSectionData();
            this.removeZerosTaxOnEmploymentSecData();

            // console.log('test object',Object.entries(this.itStatementdata[0].MonthlyRentSection.Footer));

          },
          err => {
            this._LoadingService.loaderEnd();
            this._toasterService.error(err.error.m);
          }
        );
    } catch (err) {
      this._LoadingService.loaderEnd();
      this._toasterService.error(MessageConstants.CATCH_MSG.msg);
    }
  }

  removeZerosMonthlyIncomeData() {
    this.dataElArry = '';
    for (let i = 0; i < this.itStatementdata[0].MonthlyIncome.Rows.length; i++) {
      if (this.itStatementdata[0].MonthlyIncome.Rows[i].DataElements.length > 0) {
        this.dataElArry = this.itStatementdata[0].MonthlyIncome.Rows[i].DataElements;
        for (let j = 0; j < this.dataElArry.length; j++) {
          if (this.dataElArry[j].Value > 1) {
            this.matchedDataEl = true;
          }
        }
        if (this.matchedDataEl == true) {
          this.formatedDataMonthlyIncArry.push(this.itStatementdata[0].MonthlyIncome.Rows[i]);
          this.matchedDataEl = false;
        }


      }
    }
  }
  removeZerosDeductionsData() {
    this.dataElArry = '';
    for (let i = 0; i < this.itStatementdata[0].Deductions.Rows.length; i++) {
      if (this.itStatementdata[0].Deductions.Rows[i].DataElements.length > 0) {
        this.dataElArry = this.itStatementdata[0].Deductions.Rows[i].DataElements;
        for (let j = 0; j < this.dataElArry.length; j++) {
          if (this.dataElArry[j].Value > 1) {
            this.matchedDataEl = true;
          }
        }
        if (this.matchedDataEl == true) {
          this.formatedDataDeductionsArry.push(this.itStatementdata[0].Deductions.Rows[i]);
          this.matchedDataEl = false;
        }

      }
    }
  }
  removeZerosPerquisiteSectionData() {
    this.dataElArry = '';
    for (let i = 0; i < this.itStatementdata[0].PerquisiteSection.Rows.length; i++) {
      if (this.itStatementdata[0].PerquisiteSection.Rows[i].DataElements.length > 0) {
        this.dataElArry = this.itStatementdata[0].PerquisiteSection.Rows[i].DataElements;
        for (let j = 0; j < this.dataElArry.length; j++) {
          if (this.dataElArry[j].Value > 1) {
            this.matchedDataEl = true;
          }
        }
        if (this.matchedDataEl == true) {
          this.formatedDataPerquisiteSectionArry.push(this.itStatementdata[0].PerquisiteSection.Rows[i]);
          this.matchedDataEl = false;
        }


      }
    }
  }
  removeZerosExemptionsSectionData() {
    this.dataElArry = '';
    for (let i = 0; i < this.itStatementdata[0].ExemptionsSection.Rows.length; i++) {
      if (this.itStatementdata[0].ExemptionsSection.Rows[i].DataElements.length > 0) {
        this.dataElArry = this.itStatementdata[0].ExemptionsSection.Rows[i].DataElements;
        for (let j = 0; j < this.dataElArry.length; j++) {
          if (this.dataElArry[j].Value > 1) {
            this.matchedDataEl = true;
          }
        }
        if (this.matchedDataEl == true) {
          this.formatedDataExemptionsSectionArry.push(this.itStatementdata[0].ExemptionsSection.Rows[i]);
          this.matchedDataEl = false;
        }


      }
    }
  }
  removeZerosPreviousEmplyrSectionData() {
    this.dataElArry = '';
    for (let i = 0; i < this.itStatementdata[0].PreviousEmplyrSection.PrevEmplyrKey.length; i++) {
      if (this.itStatementdata[0].PreviousEmplyrSection.PrevEmplyrKey[i].DataElements.length > 0) {
        this.dataElArry = this.itStatementdata[0].PreviousEmplyrSection.PrevEmplyrKey[i].DataElements;
        for (let j = 0; j < this.dataElArry.length; j++) {
          if (this.dataElArry[j].Value > 1) {
            this.matchedDataEl = true;
          }
        }
        if (this.matchedDataEl == true) {
          this.formatedDataPreviousEmplyrSectionArry.push(this.itStatementdata[0].PreviousEmplyrSection.PrevEmplyrKey[i]);
          this.matchedDataEl = false;
        }


      }
    }
  }
  removeZerosTaxOnEmploymentSecData() {
    this.dataElArry = '';
    for (let i = 0; i < this.itStatementdata[0].TaxOnEmploymentSec.Rows.length; i++) {
      if (this.itStatementdata[0].TaxOnEmploymentSec.Rows[i].DataElements.length > 0) {
        this.dataElArry = this.itStatementdata[0].TaxOnEmploymentSec.Rows[i].DataElements;
        for (let j = 0; j < this.dataElArry.length; j++) {
          if (this.dataElArry[j].Value > 1) {
            this.matchedDataEl = true;
          }
        }
        if (this.matchedDataEl == true) {
          this.formatedDataTaxOnEmploymentSecArry.push(this.itStatementdata[0].TaxOnEmploymentSec.Rows[i]);
          this.matchedDataEl = false;
        }


      }
    }
    console.log('Formatted Tax Sec', this.formatedDataTaxOnEmploymentSecArry)
  }

  // // GET MONTH NAME
  // getMonthname(num) {
  //   var str = Utils.convertNoToMonthString(num);
  //   return str.substring(0, 3)
  // }

  // CONSTRUCTER
  constructor(
    private _httpClientService: HttpClientService,
    private _router: Router,
    private _toasterService: ToasterService,
    private _LoadingService: LoadingService,
  ) { }

  // NGONINIT
  ngOnInit() {

    // GET DETAILS
    this.empid = localStorage.getItem('empid');
    this.fy = localStorage.getItem('fy');
    this.branch = localStorage.getItem('branch');
    let fy = this.fy.split('-');
    this.fy = fy[0] + fy[1];

    // GET IT STAMENT API METHOD
    this.getItData();


  }

}
