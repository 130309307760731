import { LoggedInUserService } from './../../../../services/loggedInUser.service';
import { HttpClientService } from 'src/app/services/httpClient.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


declare function enableTreeMenu(): any; // reading external js method

const arrayToTree = require('array-to-tree'); // flat menu to tree menu


@Component({
  selector: 'app-left-side-menu',
  templateUrl: './left-side-menu.component.html',
  styleUrls: ['./left-side-menu.component.css']
})
export class LeftSideMenuComponent implements OnInit {

  menuElements;
  public reportingManager
  public loginUserDetails;
  constructor(
    private _httpClientService: HttpClientService, private _loggedInUserService: LoggedInUserService
  ) { }


  Removeclass() {
    var body = document.body;
    body.classList.remove("sidebar-open");
  }

  flatToHierarchy(flat) {

    // sorting before sending to arrayToTree
    flat.sort((a, b) => { return a.order - b.order; });
    console.log('Ordered Menu', flat);

    this.menuElements = arrayToTree(flat, {
      parentProperty: 'parent',
      customID: 'key'
    });
    console.log('MenuElements', this.menuElements);

    // calling external js method to prepare child menu
    enableTreeMenu();
  }
  ngOnInit() {
    console.log('loginUserDetails', JSON.parse(localStorage.getItem('Reporting_Manager')));
    this.loginUserDetails = JSON.parse(localStorage.getItem('Login_UserProfile'));
    this.reportingManager = JSON.parse(localStorage.getItem('Reporting_Manager'));
    // // switch-session calling
    // this._httpClientService.post('sessions/E/ESS', '', 'apiDevBaseUrlsys').subscribe(
    //   // success callback
    //   (switchSessionRes: any) => {
    //     console.log('switchSessionRes', switchSessionRes);
    //     localStorage.setItem('MenuElements', JSON.stringify(switchSessionRes.d.MenuElements));
    //     this.flatToHierarchy(switchSessionRes.d.MenuElements);
    //   },
    //   // error callback
    //   switchSessionError => {
    //     console.log('switchSessionError', switchSessionError);
    //   }
    // );

    enableTreeMenu();

  }


}
