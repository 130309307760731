// The file contents constant values like messages.

export const MessageConstants = {

  // Common Toster Messages
  SUCCESS: { msg: 'Saved Successfully' },
  SUCCESS_COMPLETED: { msg: 'Successfully Completed' },
  UPDATE: { msg: 'Updated Successfully' },
  DELETE: { msg: 'Deleted Successfully' },
  ACTIVATE: { msg: 'Activated Successfully' },
  DEACTIVATE: { msg: 'Deactivated Successfully' },
  CATCH_MSG: { msg: 'Something went Wrong. Please Try Again..!' },

  
  // Special Attributes
  SUBMIT_MSG: { msg: 'Submitted Successfully' },
  SAVE_MSG: { msg: 'Saved Successfully' },
  UPDATE_MSG: { msg: 'Updated Successfully' },
  SAVED_AS_DRAFT: { msg: 'Saved as Draft' },
  APPROVED_MSG: { msg: 'Approved Successfully' },
  REJECTED_MSG: { msg: 'Rejected Successfully' },

  // workflow
  ADD_WORKFLOW_MSG: { msg: 'WorkFlow Added Successfully' },
  UPDATE_WORKFLOW_MSG: { msg: 'Workflow Updated Successfully' },
  DELETE_WORKFLOW_MSG: { msg: 'Deleted Successfully' },
  ASSIGN_ACTOR_MSG: { msg: 'Actor assigned Successfully' },

    // IT Declarations
    HOUSE_RENT_ADD_SUCCESSMSG: { msg: 'House Rent Added Successfully' },
    HOUSE_RENT_UPDATE_SUCCESSMSG: { msg: 'House Rent Updated Successfully' },
    HOUSE_RENT_DELETE_SUCCESSMSG: { msg: 'House Rent Deleted Successfully' },
    PREVIOUSEMP_ADD_SUCCESSMSG: { msg: 'Previous Employee Added Successfully' },
    PREVIOUSEMP_UPDATE_SUCCESSMSG: {
      msg: 'Previous Employee Updated Successfully',
    },
    PREVIOUSEMP_DELETE_SUCCESSMSG: {
      msg: 'Previous Employee Deleted Successfully',
    },
    COMPUTE_TAX_SUCCESSMESSAGE: { msg: 'Compute Tax Saved Successfully' },
    IT_DECLARATION_DRAFT_MSG: { msg: 'IT Declaration Saved Sucessfully' },
    IT_DECLARATION_SUBMIT_MSG: { msg: 'IT Declaration Submitted Sucessfully' },
    IT_DECLARATION_APPROVE_MSG: { msg: 'IT Declaration Approved Sucessfully' },
    IT_DECLARATION_REJECT_MSG: { msg: 'IT Declaration Rejected Sucessfully' }




};

// validaton content place here
export const ValidationMessageConstants = {
  // Common Validation
  STATE: { msg: 'State field mandatory' },
  CITY: { msg: 'City field mandatory' },
  COUNTRY: { msg: 'Country field mandatory' },
  MOBILE: { msg: 'Mobile field mandatory' },
  MOBILEFORMAT: { msg: 'Invalid Mobile Number' },
  EMAIL: { msg: 'E-mail mandatory' },
  EMAIL_FORMAT: { msg: 'Invalid Email Id' },
  FROM_DATE: { msg: 'From date mandatory' },
  TO_DATE: { msg: 'To date mandatory' },
  DATE_MANDATORY: { msg: 'Date is mandatory' },
  GENDER: { msg: ' Gender is Mandatory' },
  DOB: { msg: 'DOB is Mandatory' },
  ONLYALPHABETSANDSPACE_PATTERN: { msg: 'Only Allows Alphabets and Space' },
  ONLYALPHANUMERICANDSPACE_PATTERN: {
    msg: 'Only Allows AlphaNumeric and Space',
  },
  ALPHANUMERICWITHHYPEN: {
    msg: '  Only Allows AlphaNumeric with space and hypen',
  },
  ALPHANUMERICWITHSPACEANDBRACKETS: {
    msg: 'Alphanumeric with space and bracket',
  },
  PAN_FORMAT: { msg: 'Invalid PAN ( accepts 4th letter only P,C)' },
  AADHARFORMAT: { msg: 'Aadhar Number must have 12 digits' },
  ADDRESSFORMAT: { msg: ' Only Allows Alphanumeric with (@ - # /, .)' },
  PINCODEFORMAT: { msg: 'PostalCode must have Minimum 6 digits.' },
  NUMERICS: { msg: 'Only Allows Numerics' },
  PERCENTAGE: { msg: 'Only Allows Numbers 0.000 to 100' },
  NOZERO: { msg: 'Value should be greater than 0' },
  YEAR: { msg: ' Year is mandatory' },
  MONTH: { msg: ' Month is mandatory' },

  // payschedule validations
  BRANCH_MAN: { msg: 'Branch is Mandatory' },
  PAYROLL_FREQUENCY: { msg: 'Payroll Frequency is Mandatory' },
  MONTHLY_CUTTOFF_DATE: { msg: 'Monthly CutOff Date is Mandatory' },

  // Bank Details component Validations
  BANK_NAME_MAN: { msg: 'Bank Name Is Mandatory' },
  BANK_NAME_VALIDATION: { msg: 'Invalid BankName Format, BankName allow only Alphabets,space' },
  BRANCH_NAME_MAN: { msg: 'Branch Name Is Mandatory' },
  BRANCH_NOT_VALID: { msg: 'Branch Not Valid.' },
  IFSC_MAN: { msg: 'IFSC code is Mandatory' },
  INVALID_IFSC: { msg: 'Invalid IFSC Code.The IFSC is an 11-character code with the first 4 alphabetic characters representing the bank name, and the last 6 characters representing the branch. The fifth character is 0 (zero) and reserved for future use.Ex:KKBK0007631' },
  ACCOUNT_NUM_MAN: { msg: 'Account Number is Mandatory' },
  ACCOUNT_NUM_NOT_VALID: { msg: 'Account Number is Not Valid.' },
  ACCOUNT_HOLDER_NAME_VALIDATION: { msg: 'Invalid AccountHoldername Format, AccountHoldername allow only Alphabets,space' },

  // Refrence codes
  CODE_MAN: { msg: 'Code is Mandatory' },
  ACCEPT_ONLY_ALPH_NUM: { msg: 'Accepts only alphabets,numbers,_,&,( ).' },
  NAME_MAN: { msg: 'Name Field is Mandatory' },
  ACCEPT_ONLY_ALPH_NUM_PLUS: { msg: 'Accepts only alphabets,numbers,+,-,&.' },

  // Holiday
  HOLIDAY_NAME: { msg: 'Name is Mandatory' },
  HOLIDAY_NAME_VALIDATION: { msg: 'Numbers and Special Characters are not allowed.' },

  // separaton in employee
  SEPERATION_MANDATORY: { msg: 'Seperation Mode is Mandatory' },
  LAST_WORKING_DATE: { msg: 'Last Working Date is Mandatory' },
  REMARKS: { msg: 'Remarks is Mandatory' },
  REMARKS_VALIDATION: { msg: 'Invalid. Accepts only characters,number' },

  // employee Basic details
  EMP_TYPE: { msg: 'Employment Type is Mandatory' },
  EMP_NUMBER: { msg: 'Employee Number is Mandatory' },
  EMP_DOJMAN: { msg: ' Date of joining is Mandatory' },
  EMP_DOJGREATERTHANDOB: {
    msg: ' Date Of Joining must be greater than Date Of Birth ',
  },
  EMP_DOSGREATERTHANDOJ: {
    msg: ' Date of Seperation must be greater than Date of Joining',
  },
  EMP_FIRSTTNAME_MAN: { msg: 'First Name is Mandatory ' },
  EMP_DOBLESSTHANDOJ: { msg: ' DateOfBirth must be less than Date of Joining' },

  // attendance &leave

  ATTENDANCE_WORKDAYS: { msg: 'Work Days is mandatory' },
  ATTENDANCE_WORKDAYSCANTGREATERTHANMONTHDAYS: {
    msg: "Work days can't be greater than payroll month days.",
  },
  ATTENDANCE_MANANDONLYNUMS: {
    msg: 'Absent Days is mandatory and Accept Numbers only ',
  },
  ATTENDANCE_ATTDAYSGREATERTHANWORKDAYS: {
    msg: "Absent days can't be greater than work days.",
  },
  ATTENDANCE_NUMWITHOUTDOT: {
    msg:
      "Invalid Format ,Accept Numbers and Numbers with dot (Accept only number '5' after decimal)",
  },
  ATTENDANCE_PRESENTDAYSMAN: { msg: ' Present Days is mandatory' },
  ATTENDANCE_PRESENTDAYSDAYSLESSTHANMONTHDAYS: {
    msg: ' Present Days should be less than or equals to Work Days.',
  },

  LEAVE_TYPE: { msg: ' Leave Type is mandatory' },
  LEAVE_DAYS: { msg: ' Leave days is mandatory and it accept only numbers.' },
  LEAVEDAYSLESSTHANEQUALTOMONTHDAYS: {
    msg: 'Leave days should be less than or equals to Payroll Month days.',
  },
  LEAVE_MINHALDAY: {
    msg: 'Invalid leave days,minimum it accepts half day(0.5).',
  },
  LEAVE_NUMBERSWITHDECIMAL: {
    msg:
      "Invalid leave days,Accept Numbers and Numbers with decimal (Accept only number '5' after decimal)",
  },

  // statutory
  UAN_VALIDATION: { msg: 'Allow only Numeric (12 characters only)' },
  PF_VALIDATION: { msg: 'Invalid PF Number, First 5 Alphabet, Next 17 numbers.EX :TNMAS05411000000543219' },
  EMP_CONTRIBUTION: { msg: 'Enter valid percentage(ex:1,10,100,15.055).' },

  //documents
  DOC_NAME_MANDATORY: { msg: 'Document Name is Mandatory' },
  DOC_TYPE_MANDATORY: { msg: 'Document Type is Mandatory' },
  FILE_MANDATORY: { msg: 'Please Upload File' },

  //employee master -> salary
  ANNUAL_CTC_MANDATORY: { msg: 'Annual CTC is Mandatory' },
  EFFECTIVE_FROM_MANDATORY: { msg: 'Effective From is Mandatory' },

  // configuration
  VALUE_VALIDATION: { msg: 'Please enter valid format.' },

  // Query-Tracking
  SUBJECT_VALIDATION:{msg: 'Subject is Mandatory'},
  ATTACHEMENT_VALIDATION:{msg:'Please Add Attachement'},

};
