export class AppConstants {
  DEFAULT_PROFILE_IMAGE: '../../../assets/images/profile-pic.png';
  DEFAULT_LOGO_IMAGE: '../../../assets/images/sample-logo.svg';
  WHITE_BACKGROUND_IMAGE: '../../../assets/images/White_Background.jpg';
  LOGO_LOADING_IMAGE: '../../../assets/images/logo-loader.gif';
  ESI_COMPONENTS = 'EAR,ALW';
  PF_FORMULA_KEY = 'PF-Basic-Salary';
  ESI_FORMULA_KEY = 'ESI-Basic-Salary';
  PT_FORMULA_KEY = 'PT-Gross-Salary';

  SYS_TENANTID: {
    tenantId: 'SYS';
  };

  MONTH_TYPE = [
    // {
    //   id: "*",
    //   month: "All"
    // },
    {
      id: 1,
      month: 'January',
    },
    {
      id: 2,
      month: 'February',
    },
    {
      id: 3,
      month: 'March',
    },
    {
      id: 4,
      month: 'April',
    },
    {
      id: 5,
      month: 'May',
    },
    {
      id: 6,
      month: 'June',
    },
    {
      id: 7,
      month: 'July',
    },
    {
      id: 8,
      month: 'August',
    },
    {
      id: 9,
      month: 'September',
    },
    {
      id: 10,
      month: 'October',
    },
    {
      id: 11,
      month: 'November',
    },
    {
      id: 12,
      month: 'December',
    },
  ];

  DAYS = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
  ];

  MIME_TYPES: {
    pdf: 'application/pdf';
    jpg: 'image/jpeg';
    gif: 'image/gif';
    png: 'image/png';
    txtCsv: 'text/plain';
  };

  DATA_TYPE: {
    date: 'DATE';
    bool: 'BOOL';
    number: 'NUM';
    string: 'STR';
    decimal: 'DEC';
    timestamp: 'TS';
    time: 'TIME';
  };

  BUSINESS_ENTITY_TYPE: {
    SYSTEM: 'S';
    PROVIDER: 'P';
    ORG: 'O';
  };

  IN_USE: {
    Active: 'Y';
    Inactive: 'N';
  };

  MIN_WAGES_DISTRICTS = [
    'Kollam',
    'Punalur',
    'Pathanamthitta',
    'Alappuzha',
    'Kottayam',
    'Mundakayam',
    'Idukki/Munnar',
    'Ernakulam',
    'Chalakkudy',
    'Thrissur',
    'Palakkad',
    'Malappuram',
    'Kozhikode',
    'Wayanad/Meppadi',
    'Kannur',
    'Kasargod',
  ];

  // financial year wise 
  FINANCIAL_MONTH_TYPE = [
   
    {
      id: 4,
      month: 'April',
    },
    {
      id: 5,
      month: 'May',
    },
    {
      id: 6,
      month: 'June',
    },
    {
      id: 7,
      month: 'July',
    },
    {
      id: 8,
      month: 'August',
    },
    {
      id: 9,
      month: 'September',
    },
    {
      id: 10,
      month: 'October',
    },
    {
      id: 11,
      month: 'November',
    },
    {
      id: 12,
      month: 'December',
    },
    {
      id: 1,
      month: 'January',
    },
    {
      id: 2,
      month: 'February',
    },
    {
      id: 3,
      month: 'March',
    }
  ];
}
