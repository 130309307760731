import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditProfileService {
  UserEditClicked = new Subject();
  userRowData;

  // setter
  setUserRowData(selectUser) {
    this.userRowData = selectUser;
    this.UserEditClicked.next();
  }
  // getter
  getUserRowData() {
    return this.userRowData;
  }

  constructor() { }
}
