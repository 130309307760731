import { MyLinkRendererComponent } from './my-link-renderer';
import { ColDef } from 'ag-grid-community';
 
import { HttpClientService } from 'src/app/services/httpClient.service';
import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-ag-grid',
  templateUrl: './ag-grid.component.html',
  styleUrls: ['./ag-grid.component.css']
})
export class AgGridComponent implements OnInit {

  @Input()
  columnDefs: ColDef[];

  @Input()
  rowsData;

  // this constructor bind the col and row data
  constructor() {
    console.log("colDefs", this.columnDefs)
    // this.setColumns(this.columnDefs);
  }

  ngOnInit() {
    console.log("colDefs- oninit -data", this.columnDefs)
    this.setColumns(this.columnDefs);
  }


  onGridReady(params) {
    params.api.sizeColumnsToFit();
  }


  setColumns(columns) {
    this.columnDefs = [];
    console.log("coldefs after set clums", columns);
    columns.forEach((column) => {
      console.log("Colum", column);

      let definition: ColDef = { headerName: column.headerName, field: column.field, width: 120 };
      if (column.field === 'Actions') {
        definition.cellRendererFramework = MyLinkRendererComponent;
        definition.cellRendererParams = {
          inRouterLink: column,
        };

      }
      this.columnDefs.push(definition);
    });
  }
}


